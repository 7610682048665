import React from "react";
import styled from "styled-components";
import SubPageHeader from "./SubPageHeader";

type SectionProps = {
    bgcolor?: string;
};

const Section = styled.section<SectionProps>`
  box-sizing: border-box;
  min-height: 100vh;  // updated here
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.bgcolor || 'white'};
  text-align: center;
`;

const Team = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
`;

const TeamMember = styled.div`
  flex: 1 0 200px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 20px;
  border-radius: 10px;
  text-align: center;
`;

const MemberImage = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
`;

type Props = {
    shouldShowHeader: boolean;
};

const teamMembers = [
    {
        name: 'Sammie Ghabra',
        role: 'Chief Executive Officer',
        image: 'https://sg-website-images.s3.amazonaws.com/sammie.png',
        description: 'With experience spanning over six years at Amazon, Sammie Ghabra is a seasoned' +
            ' leader in the technology space. He holds a BS in Computer Science from the University of Michigan' +
            ' and a Masters degree from the University of Washington. As a senior engineer at Amazon, Sammie' +
            ' contributed significantly across the company, including front-end and back-end' +
            ' applications, AI technologies, and mobile apps on the Retail Website. Sammie also held leadership roles at Amazon, where' +
            ' he managed teams delivering over $100 million in annual revenue'
    },
    {
        name: 'Faizaan Fahsal',
        role: 'Chief Product Officer',
        image: 'https://sg-website-images.s3.amazonaws.com/faizaan.png',
        description: 'An MBA graduate from the University of Illinois at Chicago with' +
            ' additional degrees in Pharmacy, Faizaan Fahsal brings over 5 years of valuable industry' +
            ' experience to the table. His professional journey encompasses strategic roles at Fortune 500' +
            ' companies such as Blue Cross Blue Shield and United Airlines. Faizaan\'s expertise lies in' +
            ' strategic management and growth, setting him apart as an innovative and forward-thinking' +
            ' product leader.' },
    {
        name: 'Amer Musleh',
        role: 'Chief Operating Officer',
        image: 'https://sg-website-images.s3.amazonaws.com/amer.png',
        description: 'Amer Musleh, a seasoned industry professional with rich experience in the healthcare, ' +
            'restaurant, and retail industries, provides a unique blend of operational excellence and managerial ' +
            'acumen to our team. With his strategic oversight of large-scale operations, he has consistently delivered ' +
            'top-notch results, driving efficiency and customer satisfaction.' },
];

const AboutUsPage: React.FC<Props> = ({ shouldShowHeader }) => {
    return (
        <Section id="about">
            { shouldShowHeader &&
                <SubPageHeader/> }
            <h2>Meet Our Team</h2>
            <Team>
                {teamMembers.map(member => (
                    <TeamMember key={member.name}>
                        <MemberImage src={member.image} alt={member.name} />
                        <h3>{member.name}</h3>
                        <p><b>{member.role}</b></p>
                        <p>{member.description}</p>
                    </TeamMember>
                ))}
            </Team>
        </Section>
    );
};

export default AboutUsPage;
