import React from 'react';
import styled from 'styled-components';
import SubPageHeader from "./SubPageHeader";

const IntroText = styled.p`
    max-width: 800px;
    text-align: center;
`;

// Here we create a styled component named "Title"
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
`;

const Title = styled.h1`
    font-size: 2.5em;
    text-align: center;
    color: purple;
    padding-top: 50px;
`;

const ServiceSection = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
`;

const ServiceCard = styled.div`
  width: 300px;
  margin: 20px;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.75);
  text-align: left;
`;

const ServiceImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
`;

const ServiceTitle = styled.h2`
  margin: 20px 0;
`;

const ServiceDescription = styled.p`
  color: #666;
`;

const BulletPoint = styled.li`
  margin-top: 10px;
`;

const BulletList = styled.ul`
    list-style: none;
    padding-left: 0;
`;

const AIServices = () => {

    const services = [
        {
            title: 'ChatGPT Integration',
            description: 'Leverage the power of advanced AI in your everyday business interactions. We offer seamless integration of ChatGPT into your business workflows. This can transform your customer support, automate responses, and even act as a virtual assistant.',
            image: 'https://sg-website-images.s3.amazonaws.com/chatGpt.png',
            bulletPoints: [
                'Automated customer support responses',
                'Streamlined workflow processes',
                'Increased productivity with virtual assistants',
            ]
        },
        {
            title: 'Predictive Analytics',
            description: 'Turn your data into meaningful insights using our AI. Our cutting-edge predictive analytics scrutinizes your data, anticipates business trends, optimizes resource allocation, and supercharges your marketing strategies.',
            image: 'https://sg-website-images.s3.amazonaws.com/dataAnalysis.png',
            bulletPoints: [
                'Insightful data forecasting',
                'Optimized resource management',
                'Improved marketing strategy effectiveness',
            ]
        },
        {
            title: 'Image Recognition',
            description: 'Unleash the power of AI image recognition in your operations. Beneficial across various sectors like healthcare, retail, and security, it enhances disease detection, improves inventory management, and modernizes facial recognition systems',
            image: 'https://sg-website-images.s3.amazonaws.com/imageRecog.png',
            bulletPoints: [
                'Advanced disease detection in healthcare',
                'Effective inventory management in retail',
                'Secure facial recognition for security',
            ]
        },
    ];

    return (
        <div>
            <SubPageHeader/>
            <Container>
                <Title>Unleashing the Power of AI for your business</Title>
                <IntroText>Artificial Intelligence is revolutionizing businesses across the globe. At Madoour Technology, we empower your business with our advanced AI services, enabling you to stay ahead in the competitive market.</IntroText>
                <ServiceSection>
                    {services.map((service, index) => (
                        <ServiceCard key={index}>
                            <ServiceImage src={service.image} alt={service.title} />
                            <ServiceTitle>{service.title}</ServiceTitle>
                            <ServiceDescription>{service.description}</ServiceDescription>
                            <BulletList>
                                {service.bulletPoints.map((point, index) => (
                                    <BulletPoint key={index}>{point}</BulletPoint>
                                ))}
                            </BulletList>
                        </ServiceCard>
                    ))}
                </ServiceSection>
            </Container>
        </div>
    );
}

export default AIServices;
