import React, { useState } from "react";
import styled from 'styled-components';

const ContactFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  flex: 1;
  margin-left: 10px;
  @media (max-width: 800px) {
    margin-left: 0;
  }
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 20px;
  max-width: 600px;
  width: 100%;
`;

const SubmitButton = styled.button`
  padding: 10px 20px;
  background-color: #007BFF;
  color: white;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  align-self: center;
  width: fit-content;

  &:hover {
    background-color: #0056b3;
  }
`;

const Textarea = styled.textarea`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  resize: none;
`;

const Input = styled.input`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
`;

type StatusType = 'sending' | 'sent' | 'error' | null;
const ContactForm: React.FC = () => {
    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [status, setStatus] = useState<StatusType>(null);

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        const data = {
            name,
            email,
            message,
        };

        setStatus('sending');

        try {
            const response = await fetch('https://2i4jy1bbk0.execute-api.us-east-1.amazonaws.com/prod/message', {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            setStatus('sent');
        } catch (error) {
            console.error('There was an error!', error);
            setStatus('error');
        }
    };

    return (
        <ContactFormWrapper>
            <StyledForm onSubmit={handleSubmit}>
                <h2>Send Us a Message</h2>
                <p>Fill out the form below and our team will get back to you as soon as possible.</p>
                <Input type="text" placeholder="Your name" value={name} onChange={(e) => setName(e.target.value)} required />
                <Input type="email" placeholder="Your email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                <Textarea rows={4} placeholder="Your message" value={message} onChange={(e) => setMessage(e.target.value)} required />
                <SubmitButton type="submit">Submit</SubmitButton>
                {status === 'sending' && <p>Sending message...</p>}
                {status === 'sent' && <p>Message sent! We'll get back to you soon.</p>}
                {status === 'error' && <p>There was an error sending your message. We are looking into it.</p>}
            </StyledForm>
        </ContactFormWrapper>
    );
}
export default ContactForm;
