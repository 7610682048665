import React from 'react';
import styled from 'styled-components';
import SubPageHeader from "./SubPageHeader";

const IntroText = styled.p`
    max-width: 800px;
    text-align: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
`;

const Title = styled.h1`
    font-size: 2.5em;
    text-align: center;
    color: purple;
    padding-top: 50px;
`;

const ServiceSection = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
`;

const ServiceCard = styled.div`
  width: 300px;
  margin: 20px;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.75);
  text-align: left;
`;

const ServiceImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
`;

const ServiceTitle = styled.h2`
  margin: 20px 0;
`;

const ServiceDescription = styled.p`
  color: #666;
`;

const BulletPoint = styled.li`
  margin-top: 10px;
`;

const BulletList = styled.ul`
    list-style: none;
    padding-left: 0;
`;

const AppDevelopmentServices = () => {

    const services = [
        {
            title: 'iOS App Development',
            description: 'We build intuitive and engaging iOS applications to reach your Apple device users.',
            image: 'https://sg-website-images.s3.amazonaws.com/ios.png',
            bulletPoints: [
                'Custom iOS app development',
                'iOS UI/UX design',
                'App Store deployment',
            ]
        },
        {
            title: 'Android App Development',
            description: 'Our team specializes in crafting high-quality Android applications tailored to your needs.',
            image: 'https://sg-website-images.s3.amazonaws.com/android.png',
            bulletPoints: [
                'Custom Android app development',
                'Android UI/UX design',
                'Google Play Store deployment',
            ]
        },
        {
            title: 'Cross-Platform App Development',
            description: 'Get cross-platform applications compatible with both iOS and Android platforms.',
            image: 'https://sg-website-images.s3.amazonaws.com/cross.png',
            bulletPoints: [
                'React Native and Flutter apps',
                'One codebase for all platforms',
                'Faster development and deployment',
            ]
        },
    ];

    return (
        <div>
            <SubPageHeader/>
            <Container>
                <Title>Driving Business Growth with App Development Services</Title>
                <IntroText>In this mobile-first world, having a robust mobile app is crucial for your business. Madoour Technology offers a range of app development services to help you connect with your customers effectively and provide them with the best user experience.</IntroText>
                <ServiceSection>
                    {services.map((service, index) => (
                        <ServiceCard key={index}>
                            <ServiceImage src={service.image} alt={service.title} />
                            <ServiceTitle>{service.title}</ServiceTitle>
                            <ServiceDescription>{service.description}</ServiceDescription>
                            <BulletList>
                                {service.bulletPoints.map((point, index) => (
                                    <BulletPoint key={index}>{point}</BulletPoint>
                                ))}
                            </BulletList>
                        </ServiceCard>
                    ))}
                </ServiceSection>
            </Container>
        </div>
    );
}

export default AppDevelopmentServices;