import React from "react";
import styled from "styled-components";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";

const HeaderStyled = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  padding-right: 7px;
  background-color: #fff;
  z-index: 1000;
  height: auto;
  align-items: center;
`;

const Navigation = styled.nav`
  display: flex;
  gap: 20px;
  padding-right: 10px;
`;

const Logo = styled.img`
  height: 60px;  // adjust this as needed
  width: auto;
  padding-left: 12px;
`;

const AppHeader = () => {
    return (
        <HeaderStyled>
            <ScrollLink to="welcome" smooth={true}>
                <Logo src="https://sg-website-images.s3.amazonaws.com/madoour.png" alt=""/>
            </ScrollLink>
            <Navigation>
                <ScrollLink to="services" smooth={true}>Services</ScrollLink>
                <ScrollLink to="about" smooth={true}>About Us</ScrollLink>
                <ScrollLink to="contact" smooth={true}>Contact Us</ScrollLink>
            </Navigation>
        </HeaderStyled>
    );
}
export default AppHeader;
