import React from 'react';
import styled from 'styled-components';
import SubPageHeader from "./SubPageHeader";

const IntroText = styled.p`
    max-width: 800px;
    text-align: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
`;

const Title = styled.h1`
    font-size: 2.5em;
    text-align: center;
    color: purple;
    padding-top: 50px;
`;

const ServiceSection = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
`;

const ServiceCard = styled.div`
  width: 300px;
  margin: 20px;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.75);
  text-align: left;
`;

const ServiceImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
`;

const ServiceTitle = styled.h2`
  margin: 20px 0;
`;

const ServiceDescription = styled.p`
  color: #666;
`;

const BulletPoint = styled.li`
  margin-top: 10px;
`;

const BulletList = styled.ul`
    list-style: none;
    padding-left: 0;
`;

const SoftwareDevelopment = () => {

    const services = [
        {
            title: 'Backend Development',
            description: 'Our team specializes in building secure, robust, and scalable backend systems to power your business applications.',
            image: 'https://sg-website-images.s3.amazonaws.com/softwareCollab.png',
            bulletPoints: [
                'Microservices architecture',
                'Database design and management',
                'API development and integration',
                'Serverless architectures',
                'Automated testing and deployment',
            ]
        },
        {
            title: 'Frontend Development',
            description: 'We craft interactive and responsive front-end interfaces to provide the best user experience.',
            image: 'https://sg-website-images.s3.amazonaws.com/uxDesign.png',
            bulletPoints: [
                'Responsive web design',
                'Progressive web applications',
                'Single page applications',
                'UI/UX design and prototyping',
                'Performance optimization',
            ]
        },
        {
            title: 'Software Consulting',
            description: 'We provide strategic software consulting to help you make informed decisions for your technology needs.',
            image: 'https://sg-website-images.s3.amazonaws.com/consulting.png',
            bulletPoints: [
                'Software development strategy',
                'Technology stack consultation',
                'IT infrastructure design',
                'Digital transformation guidance',
                'Security audits and recommendations',
            ]
        },
    ];

    return (
        <div>
            <SubPageHeader/>
            <Container>
                <Title>Empowering Your Business with Robust Software Solutions</Title>
                <IntroText>In an increasingly digital world, having reliable and efficient software solutions is crucial. At Madoour Technology, we provide world-class software development services to transform your business operations.</IntroText>
                <ServiceSection>
                    {services.map((service, index) => (
                        <ServiceCard key={index}>
                            <ServiceImage src={service.image} alt={service.title} />
                            <ServiceTitle>{service.title}</ServiceTitle>
                            <ServiceDescription>{service.description}</ServiceDescription>
                            <BulletList>
                                {service.bulletPoints.map((point, index) => (
                                    <BulletPoint key={index}>{point}</BulletPoint>
                                ))}
                            </BulletList>
                        </ServiceCard>
                    ))}
                </ServiceSection>
            </Container>
        </div>
    );
}

export default SoftwareDevelopment;