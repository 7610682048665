import React from 'react';
import styled from 'styled-components';
import SubPageHeader from "./SubPageHeader";

const IntroText = styled.p`
    max-width: 800px;
    text-align: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
`;

const Title = styled.h1`
    font-size: 2.5em;
    text-align: center;
    color: purple;
    padding-top: 50px;
`;

const ServiceSection = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
`;

const ServiceCard = styled.div`
  width: 300px;
  margin: 20px;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.75);
  text-align: left;
`;

const ServiceImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
`;

const ServiceTitle = styled.h2`
  margin: 20px 0;
`;

const ServiceDescription = styled.p`
  color: #666;
`;

const BulletPoint = styled.li`
  margin-top: 10px;
`;

const BulletList = styled.ul`
    list-style: none;
    padding-left: 0;
`;
const DataAnalyticsServices = () => {

    const services = [
        {
            title: 'Data Processing',
            description: 'Transform raw data into meaningful insights with our advanced data processing solutions.',
            image: 'https://via.placeholder.com/800',
            bulletPoints: [
                'Data cleansing and transformation',
                'Data integration and aggregation',
                'Real-time data processing',
            ]
        },
        {
            title: 'Predictive Analytics',
            description: 'Forecast future trends and make data-driven decisions using our predictive analytics services.',
            image: 'https://via.placeholder.com/800',
            bulletPoints: [
                'Customer behavior prediction',
                'Market trend forecasting',
                'Risk and fraud detection',
            ]
        },
        {
            title: 'Big Data Analytics',
            description: 'Leverage the power of big data to derive valuable insights and enhance business performance.',
            image: 'https://via.placeholder.com/800',
            bulletPoints: [
                'Large-scale data processing',
                'Data visualization and reporting',
                'Actionable business intelligence',
            ]
        },
    ];

    return (
        <div>
            <SubPageHeader/>
            <Container>
                <Title>Empowering Businesses with Data Analytics</Title>
                <IntroText>Data is the new oil. Harness the power of data with Madoour Technology's data analytics services. We help businesses turn their data into meaningful insights, enabling them to make informed decisions and gain a competitive edge.</IntroText>
                <ServiceSection>
                    {services.map((service, index) => (
                        <ServiceCard key={index}>
                            <ServiceImage src={service.image} alt={service.title} />
                            <ServiceTitle>{service.title}</ServiceTitle>
                            <ServiceDescription>{service.description}</ServiceDescription>
                            <BulletList>
                                {service.bulletPoints.map((point, index) => (
                                    <BulletPoint key={index}>{point}</BulletPoint>
                                ))}
                            </BulletList>
                        </ServiceCard>
                    ))}
                </ServiceSection>
            </Container>
        </div>
    );
}

export default DataAnalyticsServices;