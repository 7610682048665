import React from 'react';
import styled from 'styled-components';
import Services  from './Services';
import AppHeader from "../Header";
import ContactPage from "./ContactPage";
import AboutUsPage from "./AboutUs";

type SectionProps = {
    bgcolor?: string;
};

const Tagline = styled.h2`
  font-size: 2.2em;
  text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.7);
  color: #F0F0F0;
`;

const MissionStatement = styled.h3`
  font-size: 1.5em;
  text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.7);
  color: #F0F0F0;
`;

const WelcomeSection = styled.section<SectionProps>`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(${'https://sg-website-images.s3.amazonaws.com/newBackground.png'});
  background-size: cover;
  background-position: center;
  min-height: 100vh;  // updated here
  color: white;
  text-align: center;
`;

const WelcomeSectionContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  z-index: 2;
  text-align: center;
`;

const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.32);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

// Adding a text shadow to improve text readability
const WelcomeText = styled.h1`
  position: relative;
  max-width: 600px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
`;

const Footer = styled.footer`
  background-color: #333;
  color: white;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Section = styled.section<SectionProps>`
  box-sizing: border-box;
  min-height: 100vh;  // updated here
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.bgcolor || 'white'};
  text-align: center;
`;

const Promotion = styled(Section)`
  background-color: #007BFF;
  color: white;
  font-size: 1.2em;
`;

const Button = styled.button`
  position: relative;
  background-color: #1e90ff;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  padding: 10px 24px;  // reduce padding to reduce button size
  margin: 0 10px;  // add horizontal margin for spacing between buttons
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 5px;  // make buttons slightly rounded
  width: 200px;  // ensure the buttons are the same size

  :hover {
    background-color: #008CBA;
    color: white;
  }
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;  // add some vertical spacing
`;

const ExploreButton = styled(Button)`
  background-color: #1e90ff;
  :hover {
    background-color: #008CBA;
  }
`;

const PromotionsButton = styled(Button)`
  background-color: #008CBA;
  :hover {
    background-color: #1e90ff;
  }
`;

const ContactSection = styled(Section)`
  background-color: #f8f9fa;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 50px 0;
  flex-wrap: wrap;
  margin-top: 100px;
`;

const MainPage = () => {

    const handleScrollTo = (elementId: string) => {
        const element = document.getElementById(elementId);
        if (element) {
            const top = element.getBoundingClientRect().top + window.scrollY;
            window.scrollTo({ top: top, behavior: 'smooth' });
        }
    };

    return (
        <div>
            <AppHeader/>

            <WelcomeSection id="welcome">
                <Overlay />
                <WelcomeSectionContent>
                    <Tagline> Dynamic Solutions.<br></br>
                        Innovative Technologies.</Tagline>
                    <MissionStatement> Bringing AI and Software Solutions to every day businesses </MissionStatement>
                    <ButtonWrapper>
                        <ExploreButton onClick={() => handleScrollTo('services')}>
                            Our Services
                        </ExploreButton>
                        <PromotionsButton onClick={() => handleScrollTo('promotions')}>
                            Our Promotions
                        </PromotionsButton>
                    </ButtonWrapper>
                </WelcomeSectionContent>
            </WelcomeSection>

            <Section id="services" bgcolor="#f0f0f0">
                <Services shouldShowHeader={false}/>
            </Section>

            <Promotion id="promotions">
                <h2>Special Promotion</h2>
                <WelcomeText>
                    As part of our commitment to accelerate your business growth, we're offering a 50% discount for all our software solutions. Take advantage of this limited time offer today!
                </WelcomeText>
                <WelcomeText>
                    Additionally, we will match any other offer you have at an existing software firm and provide our services at a reduced rate if we can provide our services.
                </WelcomeText>
            </Promotion>

            <AboutUsPage shouldShowHeader={false}/>

            <ContactSection id="contact">
                <ContactPage shouldShowHeader={false}/>
            </ContactSection>

            <Footer>
                <p>&copy; 2023 Madoour Technology</p>
            </Footer>
        </div>
    );
}

export default MainPage;
