import ContactForm from "./ContactForm";
import React from "react";
import styled from "styled-components";
import SubPageHeader from "./SubPageHeader";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 20px;
`;

const ContactCard = styled.div`
  flex: 1;
  margin-right: 10px;
  background-color: #FFFFFF;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  padding: 30px;
  width: 85%;
  border-radius: 10px;
  margin-bottom: 20px;
  text-align: left;
  @media (max-width: 800px) {
    margin-right: 0;
    margin-bottom: 20px;
  }

`;

const InfoTitle = styled.h3`
  margin-bottom: 5px;
  color: #495057;
`;

const InfoText = styled.p`
  color: #495057;
`;

const ContactItem = styled.div`
  margin-bottom: 15px;
`;

type Props = {
    shouldShowHeader: boolean;
};
const ContactPage: React.FC<Props> = ({ shouldShowHeader }) => {
    return (
        <div>
            { shouldShowHeader &&
            <SubPageHeader/> }
            <Container>
                <ContactCard>
                    <h2>Contact Info</h2>
                    <ContactItem>
                        <InfoTitle>Phone</InfoTitle>
                        <InfoText>6308543041</InfoText>
                    </ContactItem>
                    <ContactItem>
                        <InfoTitle>Email</InfoTitle>
                        <InfoText>info@madoour.com</InfoText>
                    </ContactItem>
                    <ContactItem>
                        <InfoTitle>Address</InfoTitle>
                        <InfoText>3012 Oak Brook Dr, Oak Book IL 60523</InfoText>
                    </ContactItem>
                    <ContactItem>
                        <InfoTitle>Hours of Operation</InfoTitle>
                        <InfoText>Monday - Sunday: 8AM - 6PM Central Time</InfoText>
                        <InfoText>24/7 full support for customer-facing applications</InfoText>
                    </ContactItem>
                </ContactCard>
                <ContactForm/>
            </Container>
        </div>
    );
};

export default ContactPage;
