import React from 'react';
import styled from 'styled-components';
import SubPageHeader from "./SubPageHeader";

const IntroText = styled.p`
    max-width: 800px;
    text-align: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
`;

const Title = styled.h1`
    font-size: 2.5em;
    text-align: center;
    color: purple;
    padding-top: 50px;
`;

const ServiceSection = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
`;

const ServiceCard = styled.div`
  width: 300px;
  margin: 20px;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.75);
  text-align: left;
`;

const ServiceImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
`;

const ServiceTitle = styled.h2`
  margin: 20px 0;
`;

const ServiceDescription = styled.p`
  color: #666;
`;

const BulletPoint = styled.li`
  margin-top: 10px;
`;

const BulletList = styled.ul`
    list-style: none;
    padding-left: 0;
`;

const CloudComputingServices = () => {

    const services = [
        {
            title: 'Cloud Migration',
            description: 'Move your business to the cloud efficiently and securely with our cloud migration services.',
            image: 'https://via.placeholder.com/800',
            bulletPoints: [
                'Assessment and planning',
                'Seamless migration process',
                'Post-migration support',
            ]
        },
        {
            title: 'Cloud Infrastructure Management',
            description: 'We manage your cloud infrastructure allowing you to focus on your core business.',
            image: 'https://via.placeholder.com/800',
            bulletPoints: [
                'Monitoring and maintenance',
                'Security and compliance',
                'Scalability and resilience',
            ]
        },
        {
            title: 'Cloud Consulting',
            description: 'Our cloud experts help you formulate the best cloud strategy for your business needs.',
            image: 'https://via.placeholder.com/800',
            bulletPoints: [
                'Cloud strategy and planning',
                'Cloud architecture design',
                'Cloud cost optimization',
            ]
        },
    ];

    return (
        <div>
            <SubPageHeader/>
            <Container>
                <Title>Accelerate Business Growth with Cloud Computing</Title>
                <IntroText>Cloud computing is reshaping the business landscape. At Madoour Technology, we offer a range of cloud computing services to help businesses harness the power of the cloud. Our services enable businesses to enhance efficiency, improve scalability, and reduce IT costs.</IntroText>
                <ServiceSection>
                    {services.map((service, index) => (
                        <ServiceCard key={index}>
                            <ServiceImage src={service.image} alt={service.title} />
                            <ServiceTitle>{service.title}</ServiceTitle>
                            <ServiceDescription>{service.description}</ServiceDescription>
                            <BulletList>
                                {service.bulletPoints.map((point, index) => (
                                    <BulletPoint key={index}>{point}</BulletPoint>
                                ))}
                            </BulletList>
                        </ServiceCard>
                    ))}
                </ServiceSection>
            </Container>
        </div>
    );
}

export default CloudComputingServices;