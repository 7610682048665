import React from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";
import SubPageHeader from "./SubPageHeader";

const ServiceContainer = styled.div`
  box-sizing: border-box; // add this
  width: 95%;
  margin: auto;
  padding-top: 60px;
`;

// Styled components for a professional, flashy look
const ServiceSection = styled.section`
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
  align-items: center; // align-items to center
`;

const CardWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: calc(250px * 4 + 30px * 3); // The total width of four cards plus three 10px margins
  margin: auto; // center the wrapper
`;

const ServiceCard = styled.div`
  flex: 1 1 auto; // take equal width based on the content
  min-width: 250px; // ensure a minimum width
  max-width: 250px;
  margin: 10px;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ServiceIcon = styled.div`
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
  background: lightgray;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2em;
`;

const Header = styled.header`
  color: #333; // change to the dark color similar to card's text
  padding: 20px;
  text-align: center;
  font-size: 2.5em; // increase size a bit
  font-weight: 700;
  margin-bottom: 20px; // add separation from the cards
  border-bottom: 2px solid #333; // add a bottom border
`;

const ServiceButton = styled.button`
    display: inline-block;
    padding: 10px 20px;
    margin-top: 20px;
    color: #ffffff;
    background-color: #333;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    text-align: center;
    font-size: 1em;
    transition: background-color 0.3s ease;
    cursor: pointer;
    &:hover {
        background-color: #555;
    }
`;

const ServiceTitle = styled.h2`
  font-size: 1.5em;
  margin-bottom: 10px;
`;

const ServiceDescription = styled.p`
  text-align: center;
  height: 65px; // adjust this according to your needs
  display: flex;
  align-items: center;
  justify-content: center;
`;

type Props = {
    shouldShowHeader: boolean;
};

const Services: React.FC<Props> = ({ shouldShowHeader }) => {

    const services = [
        {
            title: "AI Services",
            service:"ai",
            description: "Supercharge your growth with our cutting-edge AI solutions driven by machine learning and NLP.",
            icon: "🤖",
        },
        {
            title: "Software Development",
            service:"software",
            description: "Transform your business with our tailor-made software solutions optimized for the cloud.",
            icon: "💻",
        },
        {
            title: "Mobile App Development",
            service:"app",
            description: "Reach more customers through captivating mobile apps and websites, be it iOS, Android, or cross-platform.",
            icon: "📱",
        }
        //{
        //    title: "Cyber Security",
        //    service:"cyber",
        //    description: "Fortify your digital presence with our state-of-the-art cybersecurity solutions.",
        //    icon: "🛡️",
        //},
        //{
        //    title: "Data Analytics",
        //    service:"data",
        //    description: "Unlock your business potential with our powerful data analytics tools and insights.",
        //    icon: "📊",
        //},
        //{
        //    title: "Cloud Consulting",
        //    service:"cloud",
        //    description: "Achieve seamless cloud integration with our expert cloud consulting services.",
        //    icon: "☁️",
        //}
    ];

    return (
            <ServiceContainer>
                { shouldShowHeader &&
                    <SubPageHeader/> }
                <Header>Our Services</Header>
                <ServiceSection>
                    <CardWrapper>
                    {services.map((service, index) => (
                        <ServiceCard key={index}>
                            <ServiceIcon>{service.icon}</ServiceIcon>
                            <ServiceTitle>{service.title}</ServiceTitle>
                            <ServiceDescription>{service.description}</ServiceDescription>
                            <ServiceButton as={Link} to={service.service}> Learn More</ServiceButton>
                        </ServiceCard>
                    ))}
                    </CardWrapper>
                </ServiceSection>
            </ServiceContainer>
    );
}

export default Services;
