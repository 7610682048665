import React from 'react';
import styled from 'styled-components';
import SubPageHeader from "./SubPageHeader";

const IntroText = styled.p`
    max-width: 800px;
    text-align: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
`;

const Title = styled.h1`
    font-size: 2.5em;
    text-align: center;
    color: purple;
    padding-top: 50px;
`;

const ServiceSection = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
`;

const ServiceCard = styled.div`
  width: 300px;
  margin: 20px;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.75);
  text-align: left;
`;

const ServiceImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
`;

const ServiceTitle = styled.h2`
  margin: 20px 0;
`;

const ServiceDescription = styled.p`
  color: #666;
`;

const BulletPoint = styled.li`
  margin-top: 10px;
`;

const BulletList = styled.ul`
    list-style: none;
    padding-left: 0;
`;

const CyberSecurityServices = () => {

    const services = [
        {
            title: 'Security Assessment',
            description: 'We analyze your system and networks to identify potential vulnerabilities and offer solutions.',
            image: 'https://via.placeholder.com/800',
            bulletPoints: [
                'System vulnerabilities identification',
                'Security gap analysis',
                'Risk assessment',
            ]
        },
        {
            title: 'Incident Response',
            description: 'Our team offers swift incident response to minimize damages and recover from security breaches.',
            image: 'https://via.placeholder.com/800',
            bulletPoints: [
                '24/7 incident response',
                'Breach containment',
                'Post-incident analysis',
            ]
        },
        {
            title: 'Security Consulting',
            description: 'We provide security consulting services to help you build secure infrastructures and policies.',
            image: 'https://via.placeholder.com/800',
            bulletPoints: [
                'Security architecture design',
                'Policy and procedure development',
                'Compliance consulting',
            ]
        },
    ];

    return (
        <div>
            <SubPageHeader/>
            <Container>
                <Title>Enhancing Business Security with our Cybersecurity Services</Title>
                <IntroText>With increasing online threats, cybersecurity has become a necessity for businesses. At Madoour Technology, we provide top-notch cybersecurity services to protect your data and maintain your business integrity.</IntroText>
                <ServiceSection>
                    {services.map((service, index) => (
                        <ServiceCard key={index}>
                            <ServiceImage src={service.image} alt={service.title} />
                            <ServiceTitle>{service.title}</ServiceTitle>
                            <ServiceDescription>{service.description}</ServiceDescription>
                            <BulletList>
                                {service.bulletPoints.map((point, index) => (
                                    <BulletPoint key={index}>{point}</BulletPoint>
                                ))}
                            </BulletList>
                        </ServiceCard>
                    ))}
                </ServiceSection>
            </Container>
        </div>
    );
}

export default CyberSecurityServices;