import React from "react";
import styled from "styled-components";
import { Link as RouterLink } from "react-router-dom";

const HeaderStyled = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  padding-right: 22px;
  background-color: #fff;
  z-index: 1000;
  height: auto;
  align-items: center;
`;

const Navigation = styled.nav`
  display: flex;
  gap: 20px;
`;

const Logo = styled.img`
  height: 60px;
  width: auto;
  padding-left: 12px;
`;

// styled Link component
const Link = styled(RouterLink)`
  color: black; // or any color you want
  text-decoration: none;
  &:hover {
    color: #007bff; // or any color you want for hover effect
  }
`;

const SubPageHeader = () => {
    return (
        <HeaderStyled>
            <Link to="/">
                <Logo src="https://sg-website-images.s3.amazonaws.com/madoour.png" alt=""/>
            </Link>
            <Navigation>
                <Link to="/services">Services</Link>
                <Link to="/about">About Us</Link>
                <Link to="/contact">Contact Us</Link>
            </Navigation>
        </HeaderStyled>
    );
}

export default SubPageHeader;
