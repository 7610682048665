import React from 'react';
import { Routes, Route } from 'react-router-dom';
import MainPage from "./pages/MainPage";
import AIServices from "./pages/AIServices";
import ContactPage from "./pages/ContactPage";
import SoftwareDevelopment from "./pages/SoftwareServices";
import CloudComputingServices from "./pages/Cloud";
import DataAnalyticsServices from "./pages/DataAnalytics";
import CyberSecurityServices from "./pages/Cyber";
import AppDevelopmentServices from "./pages/AppDevelopment";
import ScrollToTop from "./SrollToTop";
import Services from "./pages/Services";
import AboutUsPage from "./pages/AboutUs";

const App = () => {
    return (
        <div>
            <ScrollToTop/>
            <Routes>
                <Route path="/" element={<MainPage />} />
                <Route path="/ai" element={<AIServices />} />
                <Route path="/services/ai" element={<AIServices />} />
                <Route path="/software" element={<SoftwareDevelopment />} />
                <Route path="/services/software" element={<SoftwareDevelopment />} />
                <Route path="/app" element={<AppDevelopmentServices />} />
                <Route path="/services/app" element={<AppDevelopmentServices />} />
                <Route path="/cyber" element={<CyberSecurityServices />} />
                <Route path="/data" element={<DataAnalyticsServices />} />
                <Route path="/cloud" element={<CloudComputingServices />} />
                <Route path="/services" element={<Services shouldShowHeader={true} />} />
                <Route path="/contact" element={<ContactPage shouldShowHeader={true} />} />
                <Route path="/about" element={<AboutUsPage shouldShowHeader={true} />} />
            </Routes>
        </div>
    );
}

export default App;
